import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import style from './slider_info_bez.module.css'
import Img from "gatsby-image"


export default function SliderInfo(props)  {
    const settings = {
        dots: false,
        infinite: false,
        speed: 300,
        variableWidth: true,
        arrows: false,
      };
    return (
        <div className={style.wrapper}>
            <div className={style.slider_wrapper}>
            <Slider {...settings}>
            {props.foto.map((e, i) => (
                <Img className={style.fotka} fixed={e} />
              ))}
            </Slider>
            </div>
           
        </div>
    )
}