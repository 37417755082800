import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import KolTekst from '../comp/kol_tekst'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'

export default function Research() {
    const data = useStaticQuery(graphql`
            query {
             

                okladka: file(relativePath: { eq:  "projekty/khole/1/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },
                
                komp1: file(relativePath: { eq:  "projekty/khole/1/komp1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                        childImageSharp {
                            fluid(maxWidth: 1000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                },
                komp2: file(relativePath: { eq:  "projekty/khole/1/komp2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
            },   
            komp3: file(relativePath: { eq:  "projekty/khole/1/komp3@2x.jpg" },
            sourceInstanceName: {
                eq: "images"
              }) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
        },     
        komp4: file(relativePath: { eq:  "projekty/khole/1/komp4@2x.jpg" },
        sourceInstanceName: {
            eq: "images"
          }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
                }
            }
    },      
    komp5: file(relativePath: { eq:  "projekty/khole/1/komp5@2x.jpg" },
    sourceInstanceName: {
        eq: "images"
      }) {
        childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
            }
        }
},     
        full1: file(relativePath: { eq:  "projekty/khole/1/full1@2x.jpg" },
        sourceInstanceName: {
            eq: "images"
          }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
                }
            }
    },    
    full2: file(relativePath: { eq:  "projekty/khole/1/full2@2x.jpg" },
    sourceInstanceName: {
        eq: "images"
      }) {
        childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
            }
        }
},    


               

                
            }
            `)
    return(
        <>

        <Rozdzial tytul="IDENTYFIKACJA WIZUALNA" tekst="Główną inspiracją było odniesienie do twórczości Keitha Haringa, dodatkowo uzupełnioną o charakter wizualny sceny muzycznej z Detroit lat 90-tych. Podczas procesu projektowego zdecydowaliśmy aby wszelkie materiały wokół marki były czarnobiałe i osadzone w specyfice lo-fi." nr="01." img={data.okladka.childImageSharp.fluid}>

        <TytulPunkty tekst="Podstawą funkcjonowania labelu są analogowe wydawnictwa kasetowe. Zaprojektowaliśmy układ, który pozostaje niezmienny ale jego otwartość nie ogranicza. Każda kolejna kaseta jest oryginalna i dopasowana do kontekstu brzmienia mixtejpów. "  />

        <Komp_kol tytul1="K–HOLE" tekst1="K–Hole to „slangowe określenie subiektywnego stanu dysocjacji od ciała, podczas którego mogą wystąpić objawy podobne do schizofrenii czy eksterioryzacji” (wiki).  Stylizowane na dziurę „O” działa modułowo i w zależności od potrzeb i kontekstu publikacji może być zastępowane innymi elementami tworząc nadając nowych znaczeń znakowi marki." tytul2="Inspiracja: keith haring I muzyka z DETROIT" tekst2="Prace Keitha Haringa, współczesnego twórcy street artowego, zestawione z klimatem brudnego i twórczego Detroit stanowiły główną inspirację dla projektu marki  K–Hole." img1={data.komp1.childImageSharp.fluid} img2={data.komp2.childImageSharp.fluid} invert={true} />
        <Komp_kol img1={data.komp3.childImageSharp.fluid} img2={data.komp4.childImageSharp.fluid} invert={true} />
        <div>
            <Img fluid={data.full1.childImageSharp.fluid} />
        </div>
        <div>
            <Img fluid={data.full2.childImageSharp.fluid} />
        </div>

        <KolTekst img={data.komp5.childImageSharp.fluid} tytul="Brandbook" tekst="Nawet najmniejsze projekty wymagają merytorycznego opisu, dla zachowania spójności i zrozumienia założeń projektowych. Dla K–Hole stworzyliśmy niewielki brandbook z elementami księgi znaku - to standardowa procedura dla wszystkich realizacji brandingowych tworzonych przez nasze studio." />
        
   


       



        </Rozdzial>
        
        </>
    )
}