import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Info from '../comp/punkt_flex'
import Rozdzial from '../comp/rozdzial'
import Slider_d1 from '../comp/slider_duzy'
import Slider_d from '../comp/slider_foto_bez'
import TytulPunkty from '../comp/tytul_punkty'

export default function Identyfikacja() {
    const data = useStaticQuery(graphql`
            query {
               
                okladka: file(relativePath: { eq: "projekty/khole/3/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }



               

                komp1: file(relativePath: { eq: "projekty/lato/3/komp1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp2: file(relativePath: { eq: "projekty/lato/3/komp2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full1: file(relativePath: { eq: "projekty/khole/3/full1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                full2: file(relativePath: { eq: "projekty/khole/3/full2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                slider1: file(relativePath: { eq: "projekty/khole/3/slider1/1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider2: file(relativePath: { eq: "projekty/khole/3/slider1/2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider3: file(relativePath: { eq: "projekty/khole/3/slider1/3@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider4: file(relativePath: { eq: "projekty/khole/3/slider1/4@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider5: file(relativePath: { eq: "projekty/khole/3/slider1/5@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider6: file(relativePath: { eq: "projekty/khole/3/slider1/6@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider7: file(relativePath: { eq: "projekty/khole/3/slider1/7@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider8: file(relativePath: { eq: "projekty/khole/3/slider1/8@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider9: file(relativePath: { eq: "projekty/khole/3/slider1/9@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider10: file(relativePath: { eq: "projekty/khole/3/slider1/10@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider11: file(relativePath: { eq: "projekty/khole/3/slider1/11@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider12: file(relativePath: { eq: "projekty/khole/3/slider1/12@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider13: file(relativePath: { eq: "projekty/khole/3/slider1/13@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider14: file(relativePath: { eq: "projekty/khole/3/slider1/14@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider15: file(relativePath: { eq: "projekty/khole/3/slider1/15@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                sliderr1: file(relativePath: { eq: "projekty/khole/3/slider2/1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                sliderr2: file(relativePath: { eq: "projekty/khole/3/slider2/2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                sliderr3: file(relativePath: { eq: "projekty/khole/3/slider2/3@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                sliderr4: file(relativePath: { eq: "projekty/khole/3/slider2/4@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                sliderr5: file(relativePath: { eq: "projekty/khole/3/slider2/5@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                sliderrr1: file(relativePath: { eq: "projekty/khole/3/slider3/1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                sliderrr2: file(relativePath: { eq: "projekty/khole/3/slider3/2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                sliderrr3: file(relativePath: { eq: "projekty/khole/3/slider3/3@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                sliderrr4: file(relativePath: { eq: "projekty/khole/3/slider3/4@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                sliderrr5: file(relativePath: { eq: "projekty/khole/3/slider3/5@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                sliderrr6: file(relativePath: { eq: "projekty/khole/3/slider3/6@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                sliderrr7: file(relativePath: { eq: "projekty/khole/3/slider3/7@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                sliderrr8: file(relativePath: { eq: "projekty/khole/3/slider3/8@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth:600, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
              

              

            }
            `)

    


  
    
    const slider_d = [data.slider1.childImageSharp.fluid, data.slider2.childImageSharp.fluid, data.slider3.childImageSharp.fluid, data.slider4.childImageSharp.fluid, data.slider5.childImageSharp.fluid, data.slider6.childImageSharp.fluid, data.slider7.childImageSharp.fluid, data.slider8.childImageSharp.fluid, data.slider9.childImageSharp.fluid, data.slider10.childImageSharp.fluid, data.slider11.childImageSharp.fluid, data.slider12.childImageSharp.fluid, data.slider13.childImageSharp.fluid, data.slider14.childImageSharp.fluid, data.slider15.childImageSharp.fluid]

    const slider_d1 = [data.sliderr1.childImageSharp.fluid, data.sliderr2.childImageSharp.fluid, data.sliderr3.childImageSharp.fluid, data.sliderr4.childImageSharp.fluid, data.sliderr5.childImageSharp.fluid]

    const slider_d2 = [data.sliderrr1.childImageSharp.fluid, data.sliderrr2.childImageSharp.fluid, data.sliderrr3.childImageSharp.fluid, data.sliderrr4.childImageSharp.fluid, data.sliderrr5.childImageSharp.fluid, data.sliderrr6.childImageSharp.fluid, data.sliderrr7.childImageSharp.fluid, data.sliderrr8.childImageSharp.fluid]
    return(
        <>

        <Rozdzial tytul="DIGITAL DESIGN" invert={false} tekst="Każdemu wydawnictwu K–Hole Trax towarzyszyły działania online, skupiające się na komunikacji w mediach społecznościowych i  serwisach streamingowych - głównie Soundcloud i Bandcamp. Naszym zadaniem było stworzenie oprawy wizualnej mediów marki, wynikających z tematyki kolejnych projektów i działań kontekstowych." nr="03." img={data.okladka.childImageSharp.fluid}>


         
        <TytulPunkty tekst="Obecność  cyfrowa marki rozszerzyła zakres projektów o mixtape’y wydawane w środowisku cyfrowym. Zobacz serie realizacji tworzone dla digitalowej obecności K–Hole Trax i koniecznie odwiedź media społecznościowe labelu by dowiedzieć  się  więcej!" />
        <div>
            <Img fluid={data.full1.childImageSharp.fluid} />
        </div>
        <Slider_d tytul="OKŁADKI MIXTAPE'ÓW" foto={slider_d} />
        <Info tytul="Materiały komunikacyjne" tekst="Niezbędnym elementem komunikacji cyfrowej marki były materiały promujące wydawnictwa i wydarzenia muzyczne w mediach społecznościowych i serwisach streamingowych. Zobacz przykładowe realizacje socialowe, które realizowaliśmy w Odrze." />
        <div>
            <Img fluid={data.full2.childImageSharp.fluid} />
        </div>
       

        
        
        <Slider_d1 foto={slider_d1} />
        <Slider_d foto={slider_d2} />

        

    


       
        
       

        

        
        
        </Rozdzial>
        
        </>
    )
}