import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Slick from "react-slick"
import "slick-carousel/slick/slick.css"
import strzalka from '../../../images/ikony/strzalka.svg'
import Info from '../comp/punkt_flex'
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'



function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="slider-lewo"
        style={{ ...style, display: "block"}}
        onClick={onClick}
      ><img src={strzalka} /></div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="slider-prawo"
        style={{ ...style, display: "block" }}
        onClick={onClick}
      ><img src={strzalka} /></div>
    );
  }

export default function Strategia() {
    let settingss = {
        dots: false,
        infinite: true,
        arrows: true,
        centerMode: false,
        speed: 300,

        variableWidth: true, 
        draggable: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 700,
              settings: {
              
    
        
              }
            },
            
    
        ],
      } 
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/khole/2/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                    }
                    }


                

                full1: file(relativePath: { eq: "projekty/khole/2/full1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
               
                full2: file(relativePath: { eq: "projekty/khole/2/full2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                full3: file(relativePath: { eq: "projekty/khole/2/full3@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full4: file(relativePath: { eq: "projekty/khole/2/full4@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full5: file(relativePath: { eq: "projekty/khole/2/full5@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full6: file(relativePath: { eq: "projekty/khole/2/full6@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full7: file(relativePath: { eq: "projekty/khole/2/full7@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full8: file(relativePath: { eq: "projekty/khole/2/full8@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full9: file(relativePath: { eq: "projekty/khole/2/full9@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full10: file(relativePath: { eq: "projekty/khole/2/full10@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full11: file(relativePath: { eq: "projekty/khole/2/full11@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full12: file(relativePath: { eq: "projekty/khole/2/full12@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full13: file(relativePath: { eq: "projekty/khole/2/full13@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full14: file(relativePath: { eq: "projekty/khole/2/full14@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full15: file(relativePath: { eq: "projekty/khole/2/full15@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }


                

                

            }
            `)

    




    
    
    return(
        <>

        <Rozdzial tytul="PRINT" invert={true} tekst="Materiały drukowane są wyjątkowo istotnym elementem projektu - nadają unikalności i charakteru wydawnictwom kasetowym, które są filarem działań marki. Oprócz okładek kaset, tworzyliśmy również realizacje plakatowe, które promują wydarzenia muzyczne w miastach, w których artyści związani z labelem grają koncerty pod banderą K–Hole." nr="02." img={data.okladka.childImageSharp.fluid}>

        <TytulPunkty tekst="Dla zachowania garażowego „feelingu” materiały drukowane były bez dodatkowych uszlachetnień, zachowując prostą, organiczną formę „nielegali”." />  

      

 

        <div>
            <Img fluid={data.full1.childImageSharp.fluid} />
        </div>

        <Info tekst="Od 2016 roku stworzyliśmy projekty dla 7 wydawnictw kasetowych K–Hole. Każde kolejne to nowe aranżacje tworzone z innym zestawem artystów. Pomimo zbliżonej stylistyki, każda kaseta to nowa podróż, która wymagała jednocześnie utrzymania konsekwencji stylistycznej i opowiedzenia nowej historii. Zobacz wszystkie wydania!" tytul="Wydawnictwa" />
        
     

        <div>
            <Img fluid={data.full2.childImageSharp.fluid} />
        </div>

        <div>
            <Img fluid={data.full3.childImageSharp.fluid} />
        </div>
        <div>
            <Img fluid={data.full4.childImageSharp.fluid} />
        </div>
        <div>
            <Img fluid={data.full5.childImageSharp.fluid} />
        </div>
        <div>
            <Img fluid={data.full6.childImageSharp.fluid} />
        </div>
        <div>
            <Img fluid={data.full7.childImageSharp.fluid} />
        </div>
        <div>
            <Img fluid={data.full8.childImageSharp.fluid} />
        </div>
        <div>
            <Img fluid={data.full9.childImageSharp.fluid} />
        </div>
        <div>
            <Img fluid={data.full10.childImageSharp.fluid} />
        </div>
        <div>
            <Img fluid={data.full11.childImageSharp.fluid} />
        </div>
        <div>
            <Img fluid={data.full12.childImageSharp.fluid} />
        </div>
        <div>
            <Img fluid={data.full13.childImageSharp.fluid} />
        </div>

        <Slick  className="khole-slider" style={{paddingTop: '150px'}} {...settingss}>
        <iframe width="300px" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/346842106&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
        <iframe width="300px" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1244696293&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
        <iframe width="300px" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/756097110&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
        <iframe width="300px" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/640382103&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
        <iframe width="300px" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/530450073&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
        <iframe width="300px" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/467687664&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
        <iframe width="300px" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/289613483&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
        


        </Slick>
    

        
        <Info tekst="Plakat musi być. Jego dystrybucja w organicznych zakątkach miasta buduje relacje kolektywu artystycznego z miastem, w którym się pojawia i pomaga dotrzeć do najgłębszych zakamarków. Tworzone plakaty produkowane były w fizycznych nakładach, będąc jednocześnie key visualem dla późniejszych realizacji cyfrowych." tytul="Plakaty" />
        <div>
            <Img fluid={data.full14.childImageSharp.fluid} />
        </div>
        <div>
            <Img fluid={data.full15.childImageSharp.fluid} />
        </div>
        

  


       




      
     



       
        
     

        

        
        
        </Rozdzial>
        
        </>
    )
}